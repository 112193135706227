import { useMutation } from '@apollo/client';
import { Button, CircularProgress, MenuItem } from '@mui/material';
import { captureException } from '@sentry/react';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  CompanyFileType,
  CompanyFileUploadResult,
  CreateCompanyFileDocument,
  UserPermission,
} from '../../graphql/graphql-operations';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../hooks/useTranslationPrefix';
import { MAX_UPLOAD_FILE_SIZE } from '../../lib/constants';
import { BaseDialog } from './BaseDialog';
import { Flex } from './Flex';
import FormikFileSelect from './form/FormikFileSelect';
import FormikForm from './form/FormikForm';
import FormikSelect from './form/FormikSelect';
import FormikTextField from './form/FormikTextField';
import { useUser } from './UserProvider/useUserHook';

export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png'];
export const SUPPORTED_3D_MODEL_FORMATS = [
  '.abc',
  '.ABC',
  '.dae',
  '.DAE',
  '.fbx',
  '.FBX',
  '.mtl',
  '.MTL',
  '.obj',
  '.OBJ',
  '.stl',
  '.STL',
];
interface FormData {
  file: File | null;
  name: string;
  type: CompanyFileType;
}

interface Props {
  companyId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  forceType?: CompanyFileType;
}

const CreateCompanyFileDialog = ({
  open,
  companyId,
  onClose,
  onSuccess,
  forceType,
}: Props) => {
  const { _t, t } = useTranslationPrefix('CompanyFilesPage');
  const { hasAnyPermission } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState<{
    finished: number;
    outOf: number;
  } | null>(null);
  const [createMutation] = useMutation(CreateCompanyFileDocument, {
    onCompleted: () => {
      onSuccess?.();
    },
  });

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    const file = values.file!;
    let fileId: string | null = null;
    const fileSize = file.size;
    const chunkSize = 1024 * 1024 * 8; //8MB Chunk size
    let chunkIndex = 0;
    let consecutiveErrors = 0;
    const chunkCount = Math.ceil(fileSize / chunkSize);
    let lastData: CompanyFileUploadResult | null = null;
    setProgress({ finished: 0, outOf: fileSize });

    while (chunkIndex < chunkCount) {
      const offset = chunkIndex * chunkSize;
      const currentFilePart = file.slice(
        offset,
        Math.min(offset + chunkSize, file.size),
      );

      try {
        const data = await createMutation({
          variables: {
            input: {
              chunkCount,
              chunkIndex,
              chunkSize,
              fileSize,
              fileName: file.name,
              mimeType: file.type || 'application/octet-stream',
              companyId: companyId,
              name: values.name,
              type: forceType || values.type,
            },
            file: currentFilePart,
          },
        });
        const uploadData = data.data?.createCompanyFile;

        if (uploadData) {
          const { fileId: newFileId, confirmedChunk } = uploadData;
          fileId = newFileId;
          chunkIndex = confirmedChunk + 1;
          consecutiveErrors = 0;
          lastData = uploadData;
          setProgress({
            finished: Math.min(confirmedChunk * chunkSize, fileSize),
            outOf: fileSize,
          });
        }
      } catch (err) {
        console.error(err);
        consecutiveErrors++;
        if (consecutiveErrors >= 3) {
          enqueueSnackbar(_t('uploadError', 'Failed to upload file'), {
            variant: 'error',
          });
          captureException(err);
          setProgress(null);
          return;
        }
      }
    }
    //upload completed
    setProgress(null);
  };
  const canManageImages = hasAnyPermission(UserPermission.MANAGE_COMPANY_FILES);
  const canManage3dModels = hasAnyPermission(
    UserPermission.MANAGE_COMPANY_FILES_3D_MODELS,
  );

  const options: { value: CompanyFileType; label: string }[] = [
    ...(canManageImages
      ? [
          {
            value: CompanyFileType.IMAGE,
            label: t(`enum.CompanyFileType.${CompanyFileType.IMAGE}`, 'Image'),
          },
        ]
      : []),
    ...(canManage3dModels
      ? [
          {
            value: CompanyFileType.MODEL_3D,
            label: t(
              `enum.CompanyFileType.${CompanyFileType.MODEL_3D}`,
              '3D Model',
            ),
          },
        ]
      : []),
  ];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('dialog.title', 'Add new file')}
      disableEnforceFocus
    >
      {progress ? (
        <Flex justifyContent="center">
          <CircularProgress
            size="4rem"
            variant="determinate"
            value={(progress.finished / progress.outOf) * 100}
          />
        </Flex>
      ) : (
        <Formik<FormData>
          initialValues={{
            file: null,
            name: '',
            type: forceType || options[0].value,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting, values }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3}>
                <FormikTextField
                  fullWidth
                  name="name"
                  label={_t('name.label', 'Name')}
                />
                {!forceType && (
                  <FormikSelect
                    fullWidth
                    name="type"
                    label={_t('type.label', 'File type')}
                  >
                    {options.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                )}
                <FormikFileSelect
                  name="file"
                  label={_t(`file.label`, 'Add file')}
                  accept={
                    values.type === CompanyFileType.IMAGE
                      ? SUPPORTED_IMAGE_FORMATS.join(', ')
                      : SUPPORTED_3D_MODEL_FORMATS.join(', ')
                  }
                  disabled={isSubmitting}
                  color="primary"
                />
                <Flex
                  justifyContent="center"
                  mt={1}
                  gap={3}
                  flexDirection={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Button
                    id="create-playlist-folder-cancel"
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="create-playlist-folder-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.submit', 'Save')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      )}
    </BaseDialog>
  );
};

export default CreateCompanyFileDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    file: Yup.mixed()
      .required(_t('file.required', 'File is required'))
      .when('type', {
        is: CompanyFileType.IMAGE,
        then: Yup.mixed().test(
          'fileSize',
          _t('file.fileTooLarge', 'File is too large. Maximum is 10 MB'),
          (value) => {
            if (!value) {
              return true;
            }
            return value.size <= MAX_UPLOAD_FILE_SIZE;
          },
        ),
      })

      .when('type', {
        is: CompanyFileType.IMAGE,
        then: Yup.mixed().test(
          'fileType',
          _t('file.fileTypeForbidden', 'This file type is not supported.'),
          (value) => {
            if (!value) {
              return true;
            }
            return SUPPORTED_IMAGE_FORMATS.includes(value?.type);
          },
        ),
      })
      .when('type', {
        is: CompanyFileType.MODEL_3D,
        then: Yup.mixed().test(
          'fileType',
          _t('file.fileTypeForbidden', 'This file type is not supported.'),
          (value) => {
            if (!value) {
              return true;
            }
            const extension = '.' + value.name.split('.').pop();
            return SUPPORTED_3D_MODEL_FORMATS.includes(extension);
          },
        ),
      }),
    name: Yup.string().required(
      _t('name.required', 'Name for file is required'),
    ),
    type: Yup.string().required(_t('type.required', 'File type is required')),
  });
