/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  CompanyFilesDocument,
  CompanyFileType,
  InvalidateCompanyFileDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import useCompany from '../../../lib/useCompany';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import Model3dCard from './Model3dCard';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CreateCompanyFileDialog from '../../../base/components/CreateCompanyFileDialog';
import { Flex } from '../../../base/components/Flex';

const Models3dListPage = () => {
  const { _t } = useTranslationPrefix('Models3dListPage');
  const { company } = useCompany();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [invalidateConfirmOpen, setInvalidateConfirmOpen] = useState<
    string | null
  >(null);

  const { data, refetch, error, loading } = useQuery(CompanyFilesDocument, {
    variables: {
      input: {
        companyId: company?.id,
        type: CompanyFileType.MODEL_3D,
      },
    },
    skip: !company?.id,
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);

  const [invalidate] = useMutationWithSnackbar(
    'Models3dListPage.invalidateMutation',
    InvalidateCompanyFileDocument,
    {
      onCompleted: () => {
        refetch();
        setInvalidateConfirmOpen(null);
      },
    },
  );

  const handleInvalidateConfirm = async () => {
    if (invalidateConfirmOpen) {
      await invalidate({
        variables: {
          input: {
            fileId: invalidateConfirmOpen,
          },
        },
      });
    }
  };

  return !company || loading ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={_t('title', '3D Models')}>
        <Button
          id="add-3d-model-dialog"
          variant="contained"
          onClick={() => setCreateDialogOpen(true)}
          startIcon={<AddRoundedIcon />}
        >
          {_t('createFile', 'Upload 3D model file')}
        </Button>
      </PageHeader>
      <Flex style={{ whiteSpace: 'pre' }} flexDirection="column" gap={2}>
        {data?.companyFiles?.length
          ? data.companyFiles.map((file) => (
              <Model3dCard
                key={file.id}
                data={file}
                onDeleteClick={() => setInvalidateConfirmOpen(file.id)}
              />
            ))
          : _t('uploadNewFile', 'Upload new 3D model file to see it here.')}
      </Flex>
      <ConfirmDialog
        title={_t('invalidateConfirm', 'Invalidate File?')}
        open={!!invalidateConfirmOpen}
        onClose={() => setInvalidateConfirmOpen(null)}
        onConfirm={handleInvalidateConfirm}
        confirmButtonColor="error"
        confirmLabel={_t('invalidate', 'Invalidate')}
        confirmButtonStartIcon={<DeleteForeverIcon />}
      />
      <CreateCompanyFileDialog
        open={createDialogOpen}
        companyId={company.id}
        forceType={CompanyFileType.MODEL_3D}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={() => {
          refetch();
          setCreateDialogOpen(false);
        }}
      />
    </ContentWrapper>
  );
};

export default Models3dListPage;
