import { Card, CardContent } from '@mui/material';
import {
  UserGroupFragmentFragment,
  UserRole,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface Props {
  availableRoles: UserRole[];
  groups: UserGroupFragmentFragment[];
}

const UserImportHelpCard = ({ availableRoles, groups }: Props) => {
  const { _t, t } = useTranslationPrefix('UsersImportPage');

  return (
    <Card variant="outlined" elevation={0}>
      <CardContent sx={{ whiteSpace: 'pre-wrap', padding: 4 }}>
        {_t(
          'instruction.main',
          'Select a CSV file with users you want to create, or download a file template.',
        )}
        <br />
        <br />
        {_t('instruction.columns')}
        <br />
        <br />
        {_t('instruction.roles', '  Roles:')}
        <br />
        {availableRoles.map(
          (role) => `    • ${role} - ${t(`enum.UserRole.${role}`)}\r\n`,
        )}
        <br />
        {_t('instruction.userGroups', '  User groups:')}
        <br />
        {groups.map((group) => `    • ${group.id} - ${group.name}\r\n`)}
        <br />
      </CardContent>
    </Card>
  );
};

export default UserImportHelpCard;
