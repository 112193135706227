import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, CardContent, Grid, IconButton } from '@mui/material';
import React from 'react';
import { Flex } from '../../../base/components/Flex';
import { CompanyFilesQuery } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { LabeledValue } from '../../components/LabeledValue';

type CompanyFile = CompanyFilesQuery['companyFiles'][0];

interface Props {
  data: CompanyFile;
  onDeleteClick: () => void;
}

const Model3dCard = ({ data, onDeleteClick }: Props) => {
  const { _t } = useTranslationPrefix('Model3dCard');

  return (
    <Card id={`file-id-${data.id}`}>
      <CardContent>
        <Flex alignItems="center">
          <Grid container spacing={2} p={1}>
            <Grid item xs={4}>
              <LabeledValue
                size="medium"
                value={data.name}
                label={_t('name', 'Name')}
              />
            </Grid>
            <Grid item xs={4}>
              <LabeledValue
                size="medium"
                value={
                  <a href={data.url} download={true}>
                    <DownloadIcon />
                  </a>
                }
                label={_t('download', 'Download')}
              />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              <IconButton
                id={`invalidate-file-${data.id}`}
                onClick={onDeleteClick}
                color="error"
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Flex>
      </CardContent>
    </Card>
  );
};

export default React.memo(Model3dCard);
