import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../../admin/components/NotFoundDetail/NotFoundDetail';
import Models3dListPage from './Models3dListPage';

const Models3dRoute = () => {
  return (
    <Routes>
      <Route index element={<Models3dListPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default Models3dRoute;
