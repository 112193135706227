import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  styled,
  SwipeableDrawer,
} from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { BaseTypography } from '../../base/components/BaseTypography';
import { Flex } from '../../base/components/Flex';
import VrDeviceOutlinedIcon from '../../base/components/Icons/VrDeviceOutlinedIcon';
import { AuthUser } from '../../base/components/UserProvider/UserProvider';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import {
  CompanyFeature,
  UserPermission,
} from '../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import useCompany, { CompanyDetail } from '../../lib/useCompany';
import ClientSettingsMenu from './ClientSettingsMenu';
import { ClientTopBarHeight } from './ClientTopBar';
import ClientUserProfile from './ClientUserProfile';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface MenuContent {
  icon: React.ReactNode;
  labelKey: string;
  labelDefault: string;
  to: string;
  permissions: UserPermission[];
  render?: (user: AuthUser | null, company: CompanyDetail | null) => boolean;
}

const menuItems: MenuContent[] = [
  {
    icon: <MenuBookRoundedIcon />,
    labelDefault: 'Trainings',
    labelKey: 'trainings',
    to: '/trainings/user',
    permissions: [UserPermission.VIEW_MY_TRAINING_ASSIGNMENTS],
    render: (user) => !!user?.trainingCount,
  },
  {
    icon: <PeopleOutlineRoundedIcon />,
    labelDefault: 'People',
    labelKey: 'users',
    to: '/users',
    permissions: [
      UserPermission.MANAGE_COMPANY_USERS,
      UserPermission.VIEW_COMPANY_USERS,
    ],
  },
  {
    icon: <PlayArrowOutlinedIcon />,
    labelDefault: 'Launch',
    labelKey: 'launchSession',
    to: '/playlist/create-session',
    permissions: [UserPermission.LAUNCH_ANNONYMOUS_PLAYLIST],
  },
  {
    icon: <PlayArrowOutlinedIcon />,
    labelDefault: 'Group session',
    labelKey: 'groupSession',
    to: '/group-sessions',
    permissions: [UserPermission.LOGIN_CLIENT],
    render: (user, company) =>
      !!company?.features?.includes(CompanyFeature.LAUNCH_GROUP_SESSION),
  },
  {
    icon: <FormatListBulletedRoundedIcon />,
    labelDefault: 'Scenes',
    labelKey: 'launchScene',
    to: '/sales',
    permissions: [UserPermission.SALES_ROUTE],
  },
  {
    icon: <MovieOutlinedIcon />,
    labelDefault: 'Playlists',
    labelKey: 'playlist-templates',
    to: '/playlist-templates',
    permissions: [
      UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
      UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
    ],
  },
  {
    icon: <VrDeviceOutlinedIcon />,
    labelDefault: 'Your devices',
    labelKey: 'yourDevices',
    to: '/devices',
    permissions: [
      UserPermission.DISPLAY_COMPANY_VR_DEVICES,
      UserPermission.MANAGE_COMPANY_VR_DEVICES,
    ],
  },
  {
    icon: <WorkspacePremiumOutlinedIcon />,
    labelDefault: 'Reports',
    labelKey: 'reports',
    to: '/reports',
    permissions: [UserPermission.VIEW_REPORTS],
  },
  {
    icon: <ViewInArIcon />,
    labelDefault: '3D Models',
    labelKey: '3dModels',
    to: '/3d-models',
    permissions: [UserPermission.MANAGE_COMPANY_FILES_3D_MODELS],
  },
];

const homeMenuItem: MenuContent = {
  icon: <HomeOutlinedIcon />,
  labelDefault: 'Home',
  labelKey: 'home',
  to: '/',
  permissions: [],
};

const ClientSideMenu = ({ open, setOpen }: Props) => {
  const { _t } = useTranslationPrefix('ClientSideMenu');
  const { pathname } = useLocation();
  const { company } = useCompany();
  const { hasAnyPermission, user } = useUser();

  const items = menuItems
    .filter(
      (item) =>
        !item.permissions.length || hasAnyPermission(...item.permissions),
    )
    .filter((item) => !item.render || item.render(user, company));
  if (items.length === 0) {
    items.unshift(homeMenuItem);
  }

  const renderMenuItem = (item: MenuContent) => {
    const active = pathname.startsWith(item.to);
    return (
      <MenuItem
        key={item.labelKey}
        component={Link}
        onClick={() => setOpen(false)}
        to={item.to}
        className={clsx({ [classes.item]: true, [classes.itemActive]: active })}
      >
        <ListItemIcon
          sx={{
            fontSize: '1.5rem',
            color: active ? 'primary.main' : 'grey.400',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
            color: active ? 'grey.900' : 'grey.600',
          }}
        >
          {_t(item.labelKey, item.labelDefault)}
        </ListItemText>
      </MenuItem>
    );
  };

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          backgroundColor: 'backgound.paper',
          borderTopRightRadius: '.625rem',
          borderBottomRightRadius: '.625rem',
          minWidth: '30ch',
          justifyContent: 'space-between',
        },
      }}
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Flex flexDirection="column">
        <MenuHeaderWrapper>
          <BaseTypography variant="h5" uppercase>
            {_t('menu', 'Menu')}
          </BaseTypography>
          <Button
            id="sidebar-close"
            variant="outlined"
            size="small"
            onClick={() => setOpen(false)}
            startIcon={<ArrowBackRoundedIcon />}
          >
            {_t('close', 'Close')}
          </Button>
        </MenuHeaderWrapper>
        <MenuListStyled>{items.map(renderMenuItem)}</MenuListStyled>
      </Flex>
      <Flex flexDirection="column">
        <ClientSettingsMenu onMenuClose={() => setOpen(false)} />
        <ClientUserProfile onMenuClose={() => setOpen(false)} />
      </Flex>
    </SwipeableDrawer>
  );
};

export default ClientSideMenu;

const classes = {
  item: 'MenuListStyled-item',
  itemActive: 'MenuListStyled-itemActive',
};

//Styling menu item breaks types for component type link
const MenuListStyled = styled(MenuList)(
  ({ theme: { spacing, palette } }) => `
  padding: 0;
  padding-top: ${spacing(1)};
  padding-left: ${spacing(2)};

  & .${classes.item} {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${palette.grey[50]};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
 

  & .${classes.item}:last-of-type {
    border-bottom: none;
  }
`,
);

const MenuHeaderWrapper = styled(Flex)(
  ({ theme: { spacing, palette } }) => `
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 ${spacing(2)};
  height: ${ClientTopBarHeight};
  background-color: ${palette.background.paper};
  box-shadow: 24px 0px 32px rgba(0, 0, 0, 0.04),
    16px 0px 24px rgba(0, 0, 0, 0.04), 
    4px 0px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
`,
);
