import { useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { adminState } from '../../../adminState';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import { FormikFormProvider } from '../../../base/components/form/FormikForm';
import { FormikSelectMultiple } from '../../../base/components/form/FormikSelectMultiple';
import FormikTextField from '../../../base/components/form/FormikTextField';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  GetFoldersDocument,
  GetPlaylistTemplateFoldersDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../hooks/useTranslationPrefix';

interface Props {
  open: boolean;
  name: string;
  enforceOrder: boolean;
  onClose: () => void;
  onSubmit: (name: SubmitData) => Promise<void> | void;
  openCreateFolderDialog: () => void;
  playlistTemplateId?: string;
  justCreatedFolderId?: string;
}

interface FormData {
  name: string;
  enforceOrder: boolean;
  folders: string[];
}

interface SubmitData {
  name: string;
  enforceOrder: boolean;
  addedFolders: string[];
  removedFolders: string[];
}

const PlaylistTemplateNameDialog: React.VFC<Props> = (props: Props) => {
  const { user, hasAnyPermission } = useUser();
  const { open, onClose, name, onSubmit, enforceOrder } = props;
  const { _t, t } = useTranslationPrefix('PlaylistTemplateNameDialog');
  const companyId = useState(adminState.selectedCompanyId);

  const { data: playlistTemplateFoldersData } = useQuery(
    GetPlaylistTemplateFoldersDocument,
    {
      variables: {
        input: {
          playlistTemplateId: props.playlistTemplateId,
        },
      },
      skip:
        props.playlistTemplateId == null ||
        !hasAnyPermission(UserPermission.MANAGE_PLAYLIST_FOLDERS),
    },
  );
  const { data: folderData } = useQuery(GetFoldersDocument, {
    variables: {
      input: {
        companyId: companyId.value!,
      },
    },
    skip: !hasAnyPermission(UserPermission.MANAGE_PLAYLIST_FOLDERS),
  });

  const folders = useMemo(
    () =>
      folderData?.folders?.map((f) => ({
        name: f.name,
        id: f.id,
        disabled:
          f.owner.id !== user?.id &&
          !hasAnyPermission(UserPermission.MANAGE_OTHERS_PLAYLIST_FOLDERS),
      })) || [],
    [folderData?.folders],
  );

  const validFolderIds = useMemo(() => folders.map((f) => f.id), [folders]);

  const foldersInitIds = useMemo(
    () =>
      playlistTemplateFoldersData?.playlistTemplateFolders
        ?.map((folder) => folder.id)
        ?.filter((folderid) => validFolderIds.includes(folderid)) || [],
    [playlistTemplateFoldersData?.playlistTemplateFolders, validFolderIds],
  );

  const handleSubmit = async ({ enforceOrder, name, folders }: FormData) => {
    await onSubmit({
      name,
      enforceOrder,
      addedFolders: folders.filter((f) => !foldersInitIds.includes(f)),
      removedFolders: foldersInitIds.filter((f) => !folders.includes(f)),
    });
  };

  const formik = useFormik<FormData>({
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: getValidationSchema(_t),
    initialValues: {
      name: name,
      enforceOrder: enforceOrder,
      folders: foldersInitIds,
    },
  });

  useEffect(() => {
    if (props.justCreatedFolderId) {
      formik.setFieldValue(
        'folders',
        R.uniq([...formik.values.folders, props.justCreatedFolderId]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.justCreatedFolderId]);

  return (
    <BaseDialog
      open={open}
      title={_t('title', 'Save playlist')}
      onClose={onClose}
    >
      <FormikFormProvider formik={formik}>
        <FormikTextField
          name="name"
          variant="outlined"
          label={_t('form.name', 'Name')}
          fullWidth
        />
        <FormikCheckBox
          name="enforceOrder"
          label={_t('form.enforceOrder', 'Enforce order')}
        />
        <HasPermission val={UserPermission.MANAGE_PLAYLIST_FOLDERS}>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h5" sx={{ mb: 2 }}>
            {_t('form.folderTitle', 'Add to folder')}
          </Typography>
          <FormikSelectMultiple
            name="folders"
            formValues={formik.values}
            fullWidth
            label={_t('form.folders', 'Folder')}
            multiple
            color="primary"
            sx={{ width: '100%' }}
            setFieldValue={formik.setFieldValue}
            disabled={folders?.length < 1}
            options={folders}
          />
          <Button
            id="playlist-template-create-folder"
            onClick={() => props.openCreateFolderDialog()}
            startIcon={<AddIcon />}
            variant="text"
            fullWidth
          >
            {_t('newFolder', 'New folder')}
          </Button>
        </HasPermission>
        <Flex justifyContent="space-between" mt={2}>
          <Button
            id="playlist-template-name-cancel"
            color="primary"
            onClick={onClose}
            variant="outlined"
          >
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            id="playlist-template-name-confirm"
            type="submit"
            color="primary"
            variant="contained"
            sx={{ ml: 2 }}
            disabled={formik.isSubmitting}
          >
            {_t('save', 'Save playlist')}
          </Button>
        </Flex>
      </FormikFormProvider>
    </BaseDialog>
  );
};

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string()
      .min(3, _t('form.nameMin', 'Minimal number of characters is 4'))
      .required(_t('form.nameRequired', 'Name is required')),
  });

export default PlaylistTemplateNameDialog;
