import { useMutation } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, Card, Typography } from '@mui/material';
import { uuid4 } from '@sentry/utils';
import Papa, { ParseResult } from 'papaparse';
import { useState } from 'react';
import { object, string, ValidationError } from 'yup';
import { adminState } from '../../../adminState';
import { Flex } from '../../../base/components/Flex';
import {
  CreateUserDocument,
  SuspendUserByUsernameDocument,
  UserGroupFragmentFragment,
  UserRole,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { PHONE_NO_REGEX } from '../../../lib/constants';
import { formatPhone, joinStrings } from '../../../lib/strings';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import UserImportHelpCard from './components/UserImportHelpCard';
import { useAvailableRoles, useUserAvailableGroups } from './hooks';

interface CSVData {
  DELETE?: string;
  EMAIL?: string;
  EXTERNAL_ID?: string;
  FIRST_NAME?: string;
  LANGUAGE_CODE?: string;
  LAST_NAME?: string;
  PASSWORD?: string;
  PHONE_NUMBER?: string;
  PHONE_PREFIX?: string;
  ROLE?: string;
  USERNAME?: string;
  USER_GROUP?: string;
}
type RowType = undefined | 'progress' | 'error' | 'warning' | 'success';
interface RowData {
  key: string;
  data: CSVData;
  type: RowType;
  messages: { key: string; default: string }[];
}

const columns = 9;

const mapCsvDataToRowData =
  (availableRoles: UserRole[], userGroups: UserGroupFragmentFragment[]) =>
  (data: CSVData): RowData => {
    let rowType: RowType = undefined;
    let messages: string[] = [];

    let phonePrefix = data.PHONE_PREFIX?.trim() || '+420';
    if (!phonePrefix.startsWith('+')) {
      phonePrefix = '+' + phonePrefix;
    }

    const normalized: CSVData = {
      ...data,
      USERNAME: data.USERNAME?.trim() || undefined,
      EMAIL: data.EMAIL?.trim().toLocaleLowerCase() || undefined,
      EXTERNAL_ID: data.EXTERNAL_ID?.trim() || undefined,
      FIRST_NAME: data.FIRST_NAME,
      LANGUAGE_CODE: data.LANGUAGE_CODE?.trim()?.toLocaleLowerCase() || 'en',
      LAST_NAME: data.LAST_NAME?.trim() || undefined,
      PASSWORD: data.PASSWORD?.trim() || undefined,
      PHONE_NUMBER: data.PHONE_NUMBER?.trim() || undefined,
      PHONE_PREFIX: phonePrefix,
      ROLE: data.ROLE?.trim() || undefined,
      USER_GROUP: data.USER_GROUP?.trim() || undefined,
      DELETE: data.DELETE?.trim().toUpperCase() === 'X' ? 'X' : undefined,
    };

    let userSchema = object({
      USERNAME: string().required('UsersImportPage.messages.usernameRequired'),
      LAST_NAME: string().required('UsersImportPage.messages.lastNameRequired'),
      EMAIL: string().optional().email('UsersImportPage.messages.emailInvalid'),
      LANGUAGE_CODE: string()
        .required()
        .oneOf(['cs', 'en', 'de'], 'UsersImportPage.messages.languageInvalid'),
      PHONE_NUMBER: string()
        .optional()
        .matches(PHONE_NO_REGEX, 'UsersImportPage.messages.phoneNumberInvalid'),
      ROLE: string()
        .required('UsersImportPage.messages.roleRequired')
        .oneOf(availableRoles, 'UsersImportPage.messages.invalidRole'),
      USER_GROUP: string()
        .optional()
        .oneOf(
          userGroups.map((g) => g.id),
          'UsersImportPage.messages.invalidGroup',
        ),
    });

    try {
      userSchema.validateSync(normalized, { abortEarly: false });
    } catch (err) {
      if (err instanceof ValidationError) {
        rowType = 'error';
        messages = err.errors;
      }
    }

    return {
      key: uuid4(),
      data: normalized,
      messages: messages.map((k) => ({ key: k, default: k })),
      type: rowType,
    };
  };

const UsersImportPage = () => {
  const { _t, t } = useTranslationPrefix('UsersImportPage');
  const { availableRoles } = useAvailableRoles();
  const companyId = adminState.selectedCompanyId.get();
  const { groups } = useUserAvailableGroups(companyId);
  const [users, setUsers] = useState<RowData[]>([]);

  const [createUser] = useMutation(CreateUserDocument);

  const [suspendUser] = useMutation(SuspendUserByUsernameDocument);

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (
      file?.type !== 'text/csv' &&
      file?.type !== 'application/vnd.ms-excel'
    ) {
      return;
    }
    Papa.parse<CSVData>(file, {
      header: true,
      complete: (results: ParseResult<CSVData>) => {
        const users = results.data
          .filter((r) => Object.keys(r)?.length > 1) // skip last line
          .map<RowData>(mapCsvDataToRowData(availableRoles, groups || []));
        setUsers?.(users);
      },
    });
  };

  const updateUser = (user: RowData) => {
    setUsers((old: RowData[]) => {
      const index = old.findIndex((o) => o.key === user.key);
      if (index > -1) {
        return [...old.slice(0, index), user, ...old.slice(index + 1)];
      } else {
        return old;
      }
    });
  };

  const handleImportClick = async () => {
    const userData: RowData[] = JSON.parse(JSON.stringify(users)); //just to make sure it does not change under our hands
    for (const user of userData)
      if (user.data.DELETE && user.data.USERNAME) {
        await suspendUser({
          variables: {
            input: {
              companyId: companyId,
              username: user.data.USERNAME,
            },
          },
          onCompleted: (data) => {
            user.type = 'success';
            updateUser(user);
          },
          onError: (error) => {
            user.type = 'error';
            if (error.graphQLErrors?.[0]?.extensions?.messageKey) {
              user.messages = [
                {
                  key: error.graphQLErrors?.[0]?.extensions?.messageKey,
                  default:
                    error.graphQLErrors?.[0]?.extensions?.defaultMessage ||
                    error.graphQLErrors?.[0]?.extensions?.messageKey,
                },
              ];
            } else {
              user.messages = [
                {
                  key: error.message,
                  default: error.message,
                },
              ];
            }
            updateUser(user);
          },
        });
      } else {
        await createUser({
          variables: {
            companyId,
            username: user.data.USERNAME,
            role: user.data.ROLE as UserRole,
            email: user.data.EMAIL,
            externalId: user.data.EXTERNAL_ID,
            firstName: user.data.FIRST_NAME,
            lastName: user.data.LAST_NAME!,
            phonePrefix: user.data.PHONE_NUMBER && user.data.PHONE_PREFIX,
            phoneNumber: user.data.PHONE_NUMBER,
            groups: user.data.USER_GROUP && [user.data.USER_GROUP],
            password: user.data.PASSWORD,
          },

          onCompleted: (data) => {
            user.type = 'success';
            updateUser(user);
          },
          onError: (error) => {
            user.type = 'error';
            if (error.graphQLErrors?.[0]?.extensions?.messageKey) {
              user.messages = [
                {
                  key: error.graphQLErrors?.[0]?.extensions?.messageKey,
                  default:
                    error.graphQLErrors?.[0]?.extensions?.defaultMessage ||
                    error.graphQLErrors?.[0]?.extensions?.messageKey,
                },
              ];
            } else {
              user.messages = [
                {
                  key: error.message,
                  default: error.message,
                },
              ];
            }
            updateUser(user);
          },
        });
      }
  };

  const anyNotOk = users.findIndex((user) => user.type) >= 0;

  return (
    <ContentWrapper>
      <PageHeader back title={_t('title', 'Import users')}>
        <Button
          id="template-file"
          variant="text"
          color="primary"
          LinkComponent="a"
          startIcon={<DocumentScannerOutlinedIcon />}
          href="/assets/user_import_template.csv"
        >
          {_t('template', 'CSV Template')}
        </Button>
        <label htmlFor="select-csv-file">
          <input
            accept="text/csv"
            id="select-csv-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileSelected}
          />
          <Button
            id="select-csv-file-button"
            component="span"
            startIcon={<FileUploadOutlinedIcon />}
          >
            {_t('selectCsvFile', 'Select CSV file')}
          </Button>
        </label>
      </PageHeader>

      {users?.length ? (
        <>
          <Card>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${columns}, auto)`,
                pb: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'subgrid',
                  gridColumnStart: 1,
                  gridColumnEnd: columns + 1,
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                }}
              >
                <Typography fontWeight="bold" padding={1} paddingLeft={2}>
                  {_t('column.username', 'Username')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.externalId', 'External ID')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.name', 'Name')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.email', 'Email')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.phone', 'Phone')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.role', 'Role')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.userGroup', 'User group')}
                </Typography>
                <Typography fontWeight="bold" padding={1}>
                  {_t('column.language', 'Language')}
                </Typography>
                <Typography fontWeight="bold" padding={1} paddingRight={2}>
                  {_t('column.password', 'Password')}
                </Typography>
              </Box>
              {users.map((row) => (
                <Box
                  key={row.key}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'subgrid',
                    gridColumnStart: 1,
                    gridColumnEnd: columns + 1,
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    backgroundColor:
                      row.type === 'error'
                        ? 'error.lighter'
                        : row.type === 'warning'
                        ? 'warning.lighter'
                        : row.type === 'success'
                        ? 'success.lighter'
                        : undefined,
                  }}
                >
                  <Typography padding={1} paddingLeft={2}>
                    {row.data.DELETE === 'X' ? (
                      <DeleteForeverIcon color="error" />
                    ) : (
                      ''
                    )}

                    {row.data.USERNAME}
                  </Typography>
                  <Typography padding={1}>{row.data.EXTERNAL_ID}</Typography>
                  <Typography padding={1}>
                    {' '}
                    {joinStrings(' ', row.data.FIRST_NAME, row.data.LAST_NAME)}
                  </Typography>
                  <Typography padding={1}>{row.data.EMAIL}</Typography>
                  <Typography padding={1}>
                    {row.data.PHONE_NUMBER &&
                      formatPhone(
                        row.data.PHONE_PREFIX + row.data.PHONE_NUMBER,
                      )}
                  </Typography>
                  <Typography padding={1}>
                    {row.data.ROLE && t(`enum.UserRole.${row.data.ROLE}`)}
                  </Typography>
                  <Typography padding={1}>
                    {row.data.USER_GROUP &&
                      groups?.find((g) => g.id === row.data.USER_GROUP)?.name}
                  </Typography>
                  <Typography padding={1}>
                    {t(
                      `enum.language.${row.data.LANGUAGE_CODE}`,
                      row.data.LANGUAGE_CODE,
                    )}
                  </Typography>
                  <Typography padding={1} paddingRight={2}>
                    {row.data.PASSWORD}
                  </Typography>
                  <Box sx={{ gridColumnStart: 1, gridColumnEnd: columns + 1 }}>
                    {row.messages.map((message) => (
                      <Box
                        key={message.key}
                        sx={{
                          pl: 4,
                          pb: 1,
                          color:
                            row.type === 'error'
                              ? 'error.darker'
                              : row.type === 'warning'
                              ? 'warning.darker'
                              : row.type === 'success'
                              ? 'success.darker'
                              : undefined,
                        }}
                      >
                        • {t(message.key, message.default)}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
          <Flex flexDirection="row" justifyContent="end" marginTop={2}>
            <Button
              id="import-users"
              onClick={handleImportClick}
              disabled={anyNotOk}
            >
              {_t('importUsers', 'Import users')}
            </Button>
          </Flex>
        </>
      ) : (
        <UserImportHelpCard
          availableRoles={availableRoles}
          groups={groups || []}
        />
      )}
    </ContentWrapper>
  );
};

export default UsersImportPage;
